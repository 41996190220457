import React from "react";
import styled from "styled-components";
import PositionContainer from "../shared/container";
import MainTitle from "../shared/heroTitles";
import Paragraph from "../shared/paragraphs";
import { Link } from "gatsby";

// just to trigger the rebuild for the blog post

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 30px;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 540px;

  @media (max-width: 560px) {
    width: 100%;
  }
`;

const SubtitleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 499px;

  @media (max-width: 560px) {
    width: 100%;
  }
`;

const RulesBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 792px;
  gap: 40px;
  margin-bottom: 90px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const TermsTitles = styled.div`
  color: #111111;
  font-size: 20px;
  line-height: 27px;
  font-weight: bold;
  font-style: normal;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #111111;
  margin: 0px;
  padding: 0px;
`;

function Hero() {
  return (
    <Container>
      <PositionContainer>
        <ContentHolder>
          <TitleBox>
            <MainTitle features={true}>End User License Agreement</MainTitle>
          </TitleBox>
          <SubtitleContent>
            <Title terms={true}>PDFREADER.COM End User License Agreement</Title>
            <Paragraph type="center">Last update: October 4, 2022</Paragraph>
          </SubtitleContent>
          <RulesBox>
            <Box>
              <Paragraph>
                This end user license agreement (hereinafter referred to as the
                “ EULA”) constitutes a legal and binding agreement between PDF
                Pro Software Ltd., with registered office at 1383 W 8th Ave,
                Vancouver, BC V6H 3V9, Canada, Business Registration no.
                700468291BC0001, including its subsidiaries, affiliates and
                contractors acting on its behalf (hereinafter collectively
                referred to as “ Us”, “ We”, or “ Our”) and the end users and
                their heirs, agents, successors and assigns (hereinafter
                singularly and collectively referred to as “ You” or “ Your”)
                that use any of Our software products “PDF Pro”, “PDF Creator”,
                “PDF Reader”, and “PDF Writer”, and any related documentation
                (including, without limitation, standard product documentation,
                user and technical documentation, further documents that
                describe the standard functionalities thereof, etc.),
                application, component and any modification or update thereof
                (hereinafter any and all the items above mentioned will be
                singularly and collectively referred to as the “ Software”).
              </Paragraph>
              <Paragraph>
                <strong>
                  IMPORTANT NOTICE: Your continued use of the Software
                  constitutes Your expressed acceptance of and agreement to be
                  bound by this EULA, as in force from time to time. Therefore,
                  You understand and acknowledge that by downloading, accessing,
                  installing or using the Software You agree to be bound by the
                  terms of this EULA. If You do not accept such terms in their
                  entirety, You must not download, access, install or use the
                  Software. If You agree to this EULA on behalf of an entity,
                  You represent and warrant that You have the authority to bind
                  that entity to this EULA. In such an event, “You” and “Your”
                  will refer and apply to that entity or agency.
                </strong>
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>1. LICENSE – RIGHT TO USE THE SOFTWARE</TermsTitles>
              <TermsTitles>1.1. Grant of license.</TermsTitles>
              <Paragraph>
                Subject to Your timely payment of all applicable fees, You are
                granted a limited, personal, worldwide, non-assignable,
                non-sublicenseable, non-transferable and non-exclusive license
                to use the Software, as specified in the related related
                purchase order which shall be deemed to be incorporated herein
                (hereinafter referred to as the “ Order”). Unless otherwise
                specified in the Order, You are permitted to use the Software
                only on one (1) computing device.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>1.2. Scope of the license</TermsTitles>
              <Paragraph>
                You agree that You will use the Software only in connection with
                Your own normal internal use, and You will not, without Our
                express written permission, sell, lease, or otherwise provide or
                make available – either directly or via a web interface, or
                otherwise – the Software to any third party or use it for
                benefit of any third party.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>1.3. Language</TermsTitles>
              <Paragraph>
                The Software is provided in the following languages: English,
                French, German, Spanish. Support is provided in English only,
                but We may send machine-translated messages if You let Us know
                that You do not speak English.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>1.4. Reservation of rights</TermsTitles>
              <Paragraph>
                We expressly reserve all rights not expressly granted to You in
                this EULA.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>2. MINIMUM REQUIREMENTS AND ACTIVATION</TermsTitles>
              <TermsTitles>2.1. Minimum requirements</TermsTitles>
              <Paragraph>
                In order to fully install the Software, You shall have a
                Windows-based PC, with Windows 7 at a minimum operating system,
                and at least 150MB free RAM and 300Mb free hard disk space. You
                agree that You are solely responsible for these requirements.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>2.2. Software license activation</TermsTitles>
              <Paragraph>
                In order to run the Software, You shall activate a license key
                by providing Us with complete and accurate information as
                prompted by the Order form. To such purpose, You expressly
                acknowledge and agree that: (i) as part of Our measures for
                verifying the use of the activation keys, when an internet
                connection is open, the Software may test the validity of the
                key used for the same; (ii) activating or running the Software
                beyond a certain period of time may require the entry of a
                license activation key previously supplied to You.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>3. TRIAL, SUPPORT AND UPDATES</TermsTitles>
              <TermsTitles>Free Trial</TermsTitles>
              <Paragraph>
                In case You apply for a trial, We will make the required
                Software available to You on a trial basis, free of charge,
                until the earlier of: (i) the trial period of 15 (fifteen) days
                from the Software’s installation, or (ii) the start date of the
                Software fully ordered and purchased by You before the end of
                the said trial period. You acknowledge and agree that, when in
                trial mode, the Software places a watermark on the bottom of any
                newly created or edited file therewith.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>3.1. Support</TermsTitles>
              <Paragraph>
                We will use commercially reasonable efforts to provide You with
                email support within 2 (two) business days from Your support
                request.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>3.2. Updates</TermsTitles>
              <Paragraph>
                We, in Our sole discretion, reserve the right to add additional
                tools, utilities, third-party applications, features or
                functions, or to provide bug fixes, updates and upgrades, to the
                Software in order to improve and enhance the features and
                performance thereof (hereinafter collectively referred to as the
                “ Updates ”). In case We provide Updates, this EULA shall
                automatically apply thereto, unless We provide other terms along
                with such Updates. Any Updates, if available, will be provided
                to You free of charge until the end of the first year of
                duration of this EULA, thenceforth the Updates will be subject
                to Your payment of the applicable fees, as provided in Sect.
                5.1).
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>4. YOUR RESPONSIBILITIES</TermsTitles>
              <TermsTitles>4.1. General responsibilities</TermsTitles>
              <Paragraph>
                You are entirely responsible for the use of the Software and, to
                such purpose, You shall not use (or induce others to use) the
                Software for any activity that violates any applicable local,
                state, national, and international law, statute, rule, ordinance
                or regulation (hereinafter singularly and collectively referred
                to as the “ Law”).
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>4.2. License key confidentiality</TermsTitles>
              <Paragraph>
                You are entirely responsible for safeguarding and maintaining
                the security and confidentiality of Your Software’s license
                key(s). You acknowledge and accept that We will assume that any
                person using Your Software’s license key(s), either is You or is
                authorized to act on Your behalf. You undertake not to share
                Your Software’s license key(s) to any third party and to make
                every reasonable effort to prevent unauthorized third parties
                from accessing Your Software. You agree to immediately notify Us
                if You have reasons to believe that Your Software’s license
                key(s) has/have been stolen.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>4.3. Authorized Persons</TermsTitles>
              <Paragraph>
                You may authorize Your employees to access the Software,
                provided that such access shall be for Your sole benefit and in
                full compliance with this EULA. To such purpose, You shall be
                responsible for your employees’ compliance with this EULA as
                well as for all activities that occur as a result of their
                access to the Software.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>4.4. Indemnification</TermsTitles>
              <Paragraph>
                To any third party’s claim arising out of a violation of Your
                obligations under this Section, Sect. 10 (Indemnification) shall
                apply, in addition to any other rights or remedies afforded to
                Us under this EULA and/or as per the applicable Law.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>5. LICENSE FEES AND REFUND</TermsTitles>
              <TermsTitles>5.1. License fees</TermsTitles>
              <Paragraph>
                Fees due for the rights granted herein, as well as payment terms
                thereof, are specified in the applicable Order. The Updates
                service mentioned in Sect. 4.1) is free of charge for the first
                year of the term this EULA, while after the expiry of this
                period, Updates to the Software are subject to purchase, at the
                then-current applicable fees.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>5.2. Refund</TermsTitles>
              <Paragraph>
                We offer a 30-days money-back satisfaction warranty in case of
                You are not satisfied regarding the use of Software. This
                warranty shall not apply if: (i) the Software has not been used
                in accordance with this EULA; (ii) Your dissatisfaction has been
                caused by Your failure to apply Updates or any other action or
                instruction recommended by Us; (iii) Your dissatisfaction has
                been caused by Your act or omission; or (iv) Your
                dissatisfaction results from any cause outside Our reasonable
                control. We do not refund for lack of usage or for the
                discontinuation, for any reason, of any Software’s service or
                functionality.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>6. TERM AND TERMINATION</TermsTitles>
              <TermsTitles>6.1. Term and termination</TermsTitles>
              <Paragraph>
                This EULA is effective on the first day on which the Software
                was made available to You (through license key activation,
                delivery or otherwise provided in the second paragraph of this
                EULA, titled “IMPORTANT NOTICE”), and shall remain in force for
                an undefined term, until terminated terminated either: (i) by
                You, at any time, by uninstalling the Software; or (b) by Us, at
                any time, in case of Your breach of any term herein provided,
                without notice or liability of any kind in such respect. Upon
                the termination of this EULA, the applicable Software license
                key(s) will be deactivated and You no longer have rights to
                access or use the Software.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>6.2. Obligations surviving termination</TermsTitles>
              <Paragraph>
                Provisions that survive termination or expiration of this EULA
                are those relating to limitation of liability, indemnification,
                payment and others which by their nature are intended to
                survive.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>7. INTELLECTUAL PROPERTY RIGHTS</TermsTitles>
              <TermsTitles>7.1. Our ownership</TermsTitles>
              <Paragraph>
                We as Our licensors hold and retain all an any title and
                interest in and to the Software and in any Intellectual Property
                Right associated therewith. Therefore, nothing in this EULA will
                be interpreted as a transfer, in whole or in part, of any right
                in the Software to You or any other third party. For the
                purposes of this EULA, “ Intellectual Property Rights” means all
                intellectual property and other similar proprietary rights in
                any jurisdiction, whether owned or held for use under license,
                whether registered or unregistered, including such rights in and
                to: (i) trademarks, logos, trade names, and the goodwill
                associated with the foregoing; (ii) patents and patent
                applications, and any and all divisions, continuations,
                reissues, reexaminations, and extensions thereof; (iii) writings
                and other works of authorship; (iv) trade secrets, know-how,
                non-public information, proprietary information and confidential
                information, and rights to limit the use or disclosure thereof
                by any person; (v) software, including data files, source code,
                object code, application programming interfaces, databases and
                other software-related specifications and documentation; (vi)
                domain names, uniform resource locators, and internet addresses;
                (vii) technical information, specifications, drawings, records,
                documentation, ideas, knowledge, invention disclosures or other
                data; (viii) claims, causes of action and defenses relating to
                the enforcement of any of the foregoing.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>7.2. Third-party license attribution</TermsTitles>
              <Paragraph>
                The “QT for Application Development” is licensed and distributed
                by Us under the LGPLv3 open source license (available at{" "}
                <a href="https://www.gnu.org/licenses/lgpl-3.0.en.html">
                  https://www.gnu.org/licenses/lgpl-3.0.en.html
                </a>
                ). More information about the QT project can be found at
                <a href="http://info.qt.io/download-qt-for-application-development">
                  http://info.qt.io/download-qt-for-application-development
                </a>{" "}
                You can contact Our support for instructions on how to relink
                the QT library, or to receive the QT source code copy.
                Furthermore, Our Software has been also granted: (i) a
                commercial license with royalty-free distribution of the DynaPDF
                library, which can be purchased at{" "}
                <a href="https://www.dynaforms.com">
                  https://www.dynaforms.com
                </a>{" "}
                ; (ii) a developer license with royalty-free distribution for
                the Universal Document Converter library, which can be purchased
                at{" "}
                <a href="https://www.print-driver.com/">
                  https://www.print-driver.com/
                </a>
                .
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>
                7.3. Your obligations for Our Intellectual Property Rights
                preservation.
              </TermsTitles>
              <Paragraph>
                You must not, and must not cause or permit others to: (i) remove
                or alter any Software identification code, marking or notice,
                either Our or Our licensors’; (ii) copy, modify, adapt, make
                derivative works of, disassemble, decompile, reverse engineer,
                reproduce, distribute, republish any the Software or any part
                thereof; (iii) perform or disclose any benchmark or performance
                tests of the Software or any associated infrastructure (such as,
                without limitation, network discovery, port and service
                identification, vulnerability scanning, password cracking,
                remote access testing, penetration testing, etc.); (iv) license,
                sell, rent, lease, transfer, assign, distribute, host,
                outsource, or otherwise commercially exploit or make available
                the Software to any third party.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>7.4. Your feedback and suggestions</TermsTitles>
              <Paragraph>
                We may freely use Your feedbacks, suggestions, or ideas in any
                way, including in future modifications of the Software, Updates,
                other products or services, advertising or marketing materials.
                To such purpose You grant Us a perpetual, worldwide, fully
                transferable, sub-licensable, irrevocable, fully paid-up,
                royalty free license to use the feedback You provide to Us in
                any way. Notwithstanding the preceding, We will not sell,
                publish or share Your feedback in a way that could identify You
                without Your explicit permission.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>8. PRIVACY AND DATA PROTECTION</TermsTitles>
              <TermsTitles>8.1. Privacy Policy</TermsTitles>
              <Paragraph>
                You declare to have carefully read Our Privacy Policy (available
                <Link to="/privacy/">here</Link>) and got a clear understanding
                of how We collect, use, protect or otherwise handle information
                relating to an identified or identifiable person.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>8.2. Our right to access data</TermsTitles>
              <Paragraph>
                To the maximum extent permitted by the applicable Law, We
                expressly reserves the right to access, read, preserve, and
                disclose any information as we may reasonably believe necessary
                to: (i) satisfy any applicable Law or any Authority request;
                (ii) enforce this EULA (including investigation of potential
                violations thereof) or Our Intellectual Property Rights; (iii)
                detect, prevent, or otherwise address fraud, anti-piracy,
                security or technical issues (including, without limitation,
                verify a valid registration, the number of instances launched by
                the Software, the device IP address, the version of the
                Software, etc.); (iv) conduct internal statistics and analytics
                on an anonymized basis, including tracing the use of the
                Software for the purpose of correctly provide Updates,
                functionalities and improvements, or for security reasons.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>9. WARRANTY AND LIABILITY</TermsTitles>
              <Paragraph>
                To the maximum extent permitted by the applicable Law, We
                expressly reserves the right to access, read, preserve, and
                disclose any information as we may reasonably believe necessary
                to: (i) satisfy any applicable Law or any Authority request;
                (ii) enforce this EULA (including investigation of potential
                violations thereof) or Our Intellectual Property Rights; (iii)
                detect, prevent, or otherwise address fraud, anti-piracy,
                security or technical issues (including, without limitation,
                verify a valid registration, the number of instances launched by
                the Software, the device IP address, the version of the
                Software, etc.); (iv) conduct internal statistics and analytics
                on an anonymized basis, including tracing the use of the
                Software for the purpose of correctly provide Updates,
                functionalities and improvements, or for security reasons.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>8.2. Our right to access data</TermsTitles>
              <TermsTitles>9.1. DISCLAIMER OF WARRANTY</TermsTitles>
              <Paragraph>
                THE SOFTWARE IS PROVIDED “AS IS” AND “WITH ALL FAULTS”, WITHOUT
                WARRANTY OF ANY KIND. EXCEPTION MADE FOR THE WARRANTY IN SECT.
                5.2) AND TO THE MAXIMUM EXTENT PERMITTED BY THE APPLICABLE LAW,
                WE DISCLAIM ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED,
                INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF
                MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR
                PURPOSE, NON-INFRINGEMENT OR ARISING FROM COURSE OF DEALING,
                USAGE OR TRADE. TO THE FULLEST EXTENT PERMITTED OR AUTHORIZED BY
                LAW AND WITHOUT LIMITATION OF THE FOREGOING, WE DO NOT WARRANT
                THAT THE SOFTWARE WILL MEET YOUR REQUIREMENTS OR THAT THE
                SOFTWARE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>9.2. DISCLAIMER OF LIABILITY.</TermsTitles>
              <Paragraph>
                TO THE MAXIMUM EXTENT PERMITTED BY THE APPLICABLE LAW AND
                EXCEPTION MADE FOR DEATH OR PERSONAL INJURY CAUSED BY
                NEGLIGENCE, FRAUDULENT MISREPRESENTATION, OR ANY OTHER LIABILITY
                TO THE EXTENT THAT SUCH LIABILITY CANNOT BE EXCLUDED OR LIMITED
                BY THE APPLICABLE LAW, IN NO EVENT SHALL WE BE LIABLE FOR ANY
                DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR
                CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO,
                PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA OR
                PROFITS, BUSINESS INTERRUPTION, ETC.) HOWEVER CAUSED AND ON ANY
                THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR
                TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT
                OF THE USE OF THE SOFTWARE, EVEN IF WE ARE AWARE OF OR HAVE BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.{" "}
                <strong>
                  THE FOREGOING EXCLUSIONS AND DISCLAIMERS ARE AN ESSENTIAL PART
                  OF THIS EULA.
                </strong>
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>9.3. EXCLUSIVE REMEDY</TermsTitles>
              <Paragraph>
                IF ANY LIMITATION, EXCLUSION, DISCLAIMER OR OTHER PROVISION
                CONTAINED IN THIS EULA IS HELD TO BE INVALID FOR ANY REASON BY A
                COURT OF COMPETENT JURISDICTION AND WE BECOME LIABLE THEREBY FOR
                LOSS OR DAMAGE THAT MAY LAWFULLY BE LIMITED, OUR CUMULATIVE
                LIABILITY TO YOU OR ANY OTHER PARTY FOR ANY LOSS OR DAMAGES
                RESULTING FROM ANY CLAIMS, DEMANDS, OR ACTIONS ARISING OUT OF OR
                RELATING TO THIS EULA SHALL NOT EXCEED THE AGGREGATE FEES PAID
                BY YOU FOR THE SOFTWARE. NOTWITHSTANDING THE PRECEDING,{" "}
                <strong>
                  YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF
                  DISSATISFACTION WITH THE SOFTWARE AND ANY RELATED SERVICES, OR
                  ANY OTHER GRIEVANCE AFTER THE TERM PROVIDED IN SECT. 5.2),
                  SHALL BE YOUR TERMINATION AND DISCONTINUATION OF ACCESS TO, OR
                  USE OF, THE SOFTWARE.
                </strong>
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>10. INDEMNIFICATION</TermsTitles>
              <TermsTitles>10.1. Indemnification</TermsTitles>
              <Paragraph>
                You shall indemnify and hold Us harmless from and against all
                damages, losses, and expenses of any kind (including reasonable
                legal fees and costs) arose by any claim made by any third party
                (including, without limitation, any agency, public
                administrations, entities, etc.) against Us in connection with
                any of Your: (i) violation or breach of any term of this EULA;
                (ii) violation of any applicable Law, whether or not referenced
                herein; (iii) use or misuse of the Software.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>10.2. Remedies</TermsTitles>
              <Paragraph>
                You expressly agree and acknowledge that any violation of Your
                obligations pursuant to this EULA may cause Us irreparable harm
                and damage, which may not be recovered at law. Therefore, You
                agree that Our remedies for breach of this EULA may be in equity
                by way of injunctive relief, as well and any other relief
                available, whether in law or in equity.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>11. MISCELLANEOUS</TermsTitles>
              <TermsTitles>11.1 Discontinuation policy</TermsTitles>
              <Paragraph>
                The Software is subject to Our discontinuation policy, so that
                We reserve the right to discontinue all support for the Software
                and/or for any related feature, service, content or Updates, in
                accordance with such discontinuation policy.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>11.2. Assignment</TermsTitles>
              <Paragraph>
                You are not allowed to assign this EULA or any rights hereunder,
                unless with Our previous written consent. Conversely, We are
                allowed, at Our sole discretion, to assign this EULA or any
                rights hereunder to any third party, without giving prior
                notice.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>11.3. Independent entities</TermsTitles>
              <Paragraph>
                We are an independent contractor, so no partnership, joint
                venture, or agency relationship exists between Us and You. You
                and Us are each responsible for paying their own employees,
                including employment related taxes and insurance.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>11.4. Force majeure</TermsTitles>
              <Paragraph>
                We shall not be responsible for any failure to perform due to
                unforeseen circumstances or to causes beyond Our reasonable
                control, including but not limited to acts of God, war, riot,
                embargoes, acts of civil or military authorities, fire, floods,
                accidents, strikes, lockouts, or shortages of transportation,
                facilities, fuel, energy, labor or materials. In the event of
                any such delay, We will be excused from such performance to the
                extent it is delayed or prevented by such cause.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>11.5. No implied waiver</TermsTitles>
              <Paragraph>
                Any tolerance or silence by Us, even if on a continuous and
                reiterated basis, in relation to any breach or default by You of
                any provision of this EULA shall not be considered as a consent
                to such breaches and defaults and shall not affect the validity
                of the clause breached nor shall be construed as a waiver to any
                of the rights pertaining to Us pursuant to this EULA and/or the
                applicable Law.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>
                11.6. Governing law and exclusive jurisdiction
              </TermsTitles>
              <Paragraph>
                This EULA is governed by the substantive and procedural Law of
                British Columbia, Canada, without regard to conflict of law
                provisions. Any dispute arising out of, or relating to, this
                EULA shall be submitted to the exclusive jurisdiction of
                Court(s) of British Columbia, Canada.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>11.7. Severability</TermsTitles>
              <Paragraph>
                Should any part of this EULA be declared illegal or
                unenforceable, the remaining portion will remain in full force
                and effects.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>11.8. Amendments</TermsTitles>
              <Paragraph>
                We may, in our sole discretion, amend this EULA at any time by
                posting a revised version thereof on Our website at{" "}
                <Link to="/eula/">www.pdfreader.com/eula</Link>, providing
                reasonable advance notice of any amendment that includes a
                change that reduces Your rights or increases Your
                responsibilities. Your continued use of the Software following
                changes to the EULA after the effective date of a revised
                version thereof constitutes Your expressed acceptance of and
                agreement to be bound by the EULA as in force from time to time.
              </Paragraph>
            </Box>
          </RulesBox>
        </ContentHolder>
      </PositionContainer>
    </Container>
  );
}

export default Hero;
