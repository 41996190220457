import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #111111;
  width: 100%;
  margin: 8px 0px;
  text-align: ${({ type }) => (type !== "" ? "center" : "left")};

  @media (max-width: 768px) {
    text-align: ${({ typeSpecial }) => typeSpecial};
    font-size: 14px;
    line-height: 27px;
    width: 100%;
    margin: 4px 0px;
  }
`;

function Paragraph({ children, type, typeSpecial }) {
  return (
    <Text type={type} typeSpecial={typeSpecial}>
      {children}
    </Text>
  );
}

Paragraph.propTypes = {
  type: PropTypes.string,
  typeSpecial: PropTypes.string,
};

Paragraph.defaultProps = {
  type: "",
};

export default Paragraph;
