import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Title = styled.h1`
  width: ${({ instalHero }) => (instalHero ? "100%" : "540px")};
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 58px;
  color: #111111;
  box-sizing: border-box;
  margin-top: 101px;
  margin-bottom: 0px;
  text-align: ${({ features }) => (features ? "center" : "left")};

  @media (max-width: 1080px) {
    width: 100%;
    font-size: 52px;
    line-height: 58px;
  }

  @media (max-width: 475px) {
    width: 326px;
    font-size: 32px;
    line-height: 42px;
    color: #111111;
  }
  @media (max-width: 375px) {
    width: 100%;
    font-size: 32px;
    line-height: 42px;
    color: #111111;
  }
`;

function HeroTitles({ children, features, instalHero }) {
  return (
    <Title features={features} instalHero={instalHero}>
      {children}
    </Title>
  );
}

HeroTitles.propTypes = {
  features: PropTypes.bool,
  instalHero: PropTypes.bool,
};

HeroTitles.defaultProps = {
  features: false,
  instalHero: false,
};

export default HeroTitles;
