import React from "react";
import Seo from "../components/shared/seo";
import Hero from "../components/eulaPage/hero";

function Eula() {
  return (
    <>
      <Seo
        title="End User License Agreement - PDF Reader"
        noRobotsIndex={true}
        special={true}
      />
      <Hero />
    </>
  );
}

export default Eula;
